import React from "react";
import { Carousel, Col, Row, Typography } from "antd";
import layoutStyle from "./layout.m.less";
import cx from "classnames";
import s from './Prices.m.less';
import { Plan } from "./Plan";
import { CarouselProps } from "antd/lib/carousel";

const { Title, Text } = Typography;

export type TPricesProps = {};

export const Prices: React.FC<TPricesProps> = () => {
  const startupPlan = <Plan
    title={'Startup'}
    features={[
      'Up to 10 employees',
      'Responsibilities builder',
      'Employees evaluation',
      'Individual development plans',
    ]}
  />;

  const growthPlan = <Plan
    title={'Growth'}
    monthlyFee={'€4'}
    monthlyFeeDescription={'per emp. per month billing annually'}
    features={[
      'Everything from Startup',
      'No limit for max employees',
      'Email support within 24h',
      'Minimum employees in bill 35',
    ]}
  />;

  const progressPlan = <Plan
    title={'Progress'}
    setupFee={'€2,000'}
    setupFeeDescription={'setup fee and customization'}
    monthlyFee={'€4'}
    monthlyFeeDescription={'per emp. per month billing annually'}
    features={[
      'Everything from Growth',
      '20 hours of consulting included',
      'Configuration by Equalize Team',
      'Ability to integrate with your environment and develop custom features',
    ]}
  />;

  const carouselSettings: CarouselProps = {
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <div className={layoutStyle.widthLimiter}>
      <Row>
        <Col span={12} offset={11} className={cx(layoutStyle.line, layoutStyle.topRight)} style={{ height: 80 }} />
      </Row>
      <Row>
        <Col
          offset={1}
          span={22}
          className={cx(layoutStyle.line, layoutStyle.right)}
        >
          <Title className={layoutStyle.sectionTitle}>
            <Text type={'secondary'}>Plans</Text>
          </Title>
        </Col>
      </Row>
      <Row
        className={s.gridContainer}
      >
        <Col
          span={18}
          offset={3}
          className={s.gridRowContainer}
        >
          <Row gutter={20} justify={'space-between'}>
            <Col
              span={8}
            >
              {startupPlan}
            </Col>
            <Col
              span={8}
            >
              {growthPlan}
            </Col>
            <Col
              span={8}
            >
              {progressPlan}
            </Col>
          </Row>
        </Col>
        <Col
          span={2}
          className={cx(layoutStyle.line, layoutStyle.right)}
        />
      </Row>
      <Row
        className={s.carouselContainer}
      >
        <Col
          span={20}
          offset={2}
        >
          <Carousel
            className={s.carousel}
            {...carouselSettings}
          >
            {startupPlan}
            {growthPlan}
            {progressPlan}
          </Carousel>
        </Col>
        <Col
          span={1}
          className={cx(layoutStyle.line, layoutStyle.right)}
        />
      </Row>
    </div>
  );
}
