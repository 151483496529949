import React from "react";
import layoutStyle from "./layout.m.less";
import { Typography } from "antd";
import { Slogan } from "./Slogan";
import sloganStyle from "./Slogan.m.less";
import insightsImg from "../images/insights.png";
import reviewsComparisonImg from "../images/reviews-comparison.png";
import employeeDevelopmentImg from "../images/emp-development.png";
import teamStrengthImg from "../images/team-strength.png";

const { Text } = Typography;

export type TStatsSlogansProps = {};

export const StatsSlogans: React.FC<TStatsSlogansProps> = () => {
  return (
    <div className={layoutStyle.widthLimiter}>
      <Slogan
        layout={'sloganRight'}
        extra={
          <img
            className={sloganStyle.image}
            src={insightsImg}
            alt={'Employees comparisons graphs'}
          />
        }
      >
        Compare employees<br/>
        by <Text type={'secondary'}>value</Text> they bring
      </Slogan>

      <Slogan
        extra={
          <img
            className={sloganStyle.image}
            src={reviewsComparisonImg}
            alt={'Comparison of performance review rounds'}
          />
        }
      >
        Repeat performance<br />
        reviews and get<br />
        <Text type={'secondary'}>reports</Text> on progress
      </Slogan>

      <Slogan
        layout={'sloganRight'}
        extra={
          <img className={sloganStyle.image} src={employeeDevelopmentImg} alt={'Employee development diagram'} />
        }
      >
        Show employees<br />
        how they grow<br />
        <Text type={'secondary'}>over time</Text>
      </Slogan>

      <Slogan
        lineLengths={[12, 11]}
        extra={
          <img className={sloganStyle.image} src={teamStrengthImg} alt={'Team strength diagram'} />
        }>
        Know your<br />
        team <Text type={'secondary'}>strengths</Text>
      </Slogan>
    </div>
  );
}
