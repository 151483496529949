import React from "react";
import layoutStyle from "./layout.m.less";
import s from './How.m.less';
import { Col, Row, Typography } from "antd";
import cx from "classnames";
import { Step } from "./Step";
import { Callout } from "./Callout";

const { Title, Text } = Typography;

export const How: React.FC = () => {
  return (
    <div className={layoutStyle.widthLimiter}>
      <Row className={s.how} justify={"center"}>
        <div
          className={cx(layoutStyle.line, layoutStyle.right, s.line)}
        />
        <div className={cx(layoutStyle.point, layoutStyle.middle, s.point)} />
        <Title className={layoutStyle.sectionTitle}>
          <Text
            type={'secondary'}
            className={s.text}
          >How</Text>
        </Title>
      </Row>
      <Row>
        <Col
          span={1}
          offset={11}
          className={cx(layoutStyle.line, layoutStyle.bottomRight)}
          style={{ height: 20 }}
        />
      </Row>
      <Row>
        <Col
          span={10}
          offset={1}
          xl={{ span: 7, offset: 4 }}
          className={cx(layoutStyle.line, layoutStyle.topLeft)} style={{ height: 80 }}
        />
      </Row>
      <Step>
        Equalize gives you a quick way to design <Text type={'secondary'}>roles</Text>
      </Step>
      <Step>
        For every role you can define responsibility <Text type={'secondary'}>areas</Text>
      </Step>
      <Step>
        You can detail these areas describing all nested <Text type={'secondary'}>skills</Text>
      </Step>
      <Callout decoration={'fire'}>
        Clear responsibilities<br />
        prevent <em>burnouts</em>
      </Callout>
      <Step>
        At this moment you get a base for your <Text type={'secondary'}>evaluation</Text> system
      </Step>
      <Step>
        Whole team sees what’s estimated while <Text type={'secondary'}>performance review</Text>
      </Step>
      <Step>
        Employees get clear reasons to work on <Text type={'secondary'}>areas of improvement</Text>
      </Step>
      <Callout decoration={'target'}>
        Employees’ <em>goals</em><br />
        are now aligned<br />
        with company’s <em>goals</em>
      </Callout>
      <Step>
        Using employees <Text type={'secondary'}>evaluation</Text> and <Text type={'secondary'}>rates</Text> Equalize
        shows you weights
      </Step>
      <Step>
        <Text type={'secondary'}>Weights</Text> of responsibilities’ areas for every employee
      </Step>
      <Step>
        You can literally see what you pay <Text type={'secondary'}>money</Text> for
      </Step>
      <Callout decoration={'money'}>
        Detailed vision of<br />
        <em>salaries distribution</em> for<br />
        more weighted decisions
      </Callout>
      <Row>
        <Col
          span={11}
          offset={1}
          xl={{ span: 8, offset: 4 }}
          className={cx(layoutStyle.line, layoutStyle.bottomLeft)}
          style={{ height: '80px' }}
        >
        </Col>
      </Row>
    </div>
  );
}
