import React, { PropsWithChildren } from "react";
import layoutStyle from "./layout.m.less";
import s from "./Callout.m.less";
import { Col, Row, Typography } from "antd";
import cx from "classnames";

const { Title } = Typography;

export type TCalloutProps = {
  decoration: 'fire' | 'target' | 'money',
};

export const Callout: React.FC<PropsWithChildren<TCalloutProps>> = ({
  children,
  decoration,
}) => {
  return (
    <Row>
      <Col
        span={22}
        offset={1}
        xl={{ span: 16, offset: 4 }}
        className={cx(layoutStyle.line, layoutStyle.left, s.wrapper)}
      >
        <div className={cx(s.callout, s[decoration])}>
          <Title className={s.text}>
            {children}
          </Title>
        </div>
      </Col>
    </Row>
  );
}
