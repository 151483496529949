import React, { PropsWithChildren } from "react";
import { Col, Row, Typography } from "antd";
import s from './Step.m.less';
import layoutStyle from "./layout.m.less";
import cx from "classnames";

const { Title } = Typography;

export type TStepProps = {};

export const Step: React.FC<PropsWithChildren<TStepProps>> = ({
  children,
}) => {
  return (
    <Row>
      <Col
        span={22}
        offset={1}
        xl={{ offset: 4, span: 20 }}
        className={cx(layoutStyle.line, layoutStyle.left, s.step)}
      >
        <div className={cx(layoutStyle.point, layoutStyle.large, layoutStyle.middleLeft)}></div>
        <Title level={3} className={s.text}>
          {children}
        </Title>
      </Col>
    </Row>
  );
}
