import React from "react";
import layoutStyle from "./layout.m.less";
import { Col, Row, Typography } from "antd";
import cx from "classnames";
import { Slogan } from "./Slogan";
import sloganStyle from "./Slogan.m.less";
import orgStructureImg from "../images/org-structure.png";
import responsibilitiesImg from "../images/responsibilities.png";
import evaluationSystemImg from "../images/evaluation-system.png";

const { Title, Text } = Typography;

export type TWhySlogansProps = {

};

export const WhySlogans: React.FC<TWhySlogansProps> = ({}) => {
  return (
    <div className={layoutStyle.widthLimiter}>
      <Row justify={"center"}>
        <Title className={layoutStyle.sectionTitle}>Let people <Text type={'secondary'}>love</Text> their work!</Title>
      </Row>
      <Row>
        <Col span={1} offset={11} className={cx(layoutStyle.line, layoutStyle.bottomRight)} style={{ height: 80 }}>
          <div className={cx(layoutStyle.point, layoutStyle.topRight)} />
        </Col>
      </Row>
      <Slogan
        lineLengths={[11, 12]}
        extra={
          <img className={sloganStyle.image} src={orgStructureImg} alt={'Organization structure'} />
        }
      >
        Keep the company<br />structure <Text type={'secondary'}>clear</Text>
      </Slogan>
      <Slogan
        layout={'sloganRight'}
        extra={
          <img className={sloganStyle.image} src={responsibilitiesImg} alt={'Responsibilities structure'} />
        }
      >
        Build <Text type={'secondary'}>responsibilities</Text><br /> actual for your company
      </Slogan>
      <Slogan
        lineLengths={[12, 11]}
        extra={
          <img className={sloganStyle.image} src={evaluationSystemImg} alt={'Employees evaluation system'} />
        }>
        Create <Text className={layoutStyle.transparentText}>transparent</Text><br />
        evaluation system
      </Slogan>
      <Row>
        <Col span={1} offset={11} className={cx(layoutStyle.line, layoutStyle.topRight)} style={{ height: 80 }} />
      </Row>
    </div>
  );
}
