import React from "react";
import cx from "classnames";
import pricesStyle from "./Prices.m.less";
import { Typography } from "antd";
import s from './Plan.m.less';

const { Title } = Typography;

export type TPlanProps = {
  title: string,
  setupFee?: string,
  setupFeeDescription?: string,
  monthlyFee?: string,
  monthlyFeeDescription?: string,
  features: string[],
};

export const Plan: React.FC<TPlanProps> = ({
  title,
  setupFee = '€0',
  setupFeeDescription = 'for setup',
  monthlyFee = '€0',
  monthlyFeeDescription = 'monthly fee',
  features,
}) => {
  return (
    <div
      className={cx(pricesStyle.plan, pricesStyle.text)}
    >
      <Title
        level={3}
        className={pricesStyle.text}
      >
        {title}
      </Title>
      <Title className={cx(pricesStyle.text, pricesStyle.price)}>{setupFee}</Title>
      <Title level={4} className={cx(pricesStyle.text, s.description)}>{setupFeeDescription}</Title>
      <Title className={cx(pricesStyle.text, pricesStyle.price)}>{monthlyFee}</Title>
      <Title level={4} className={cx(pricesStyle.text, s.description)}>{monthlyFeeDescription}</Title>
      <ul className={pricesStyle.features}>
        {features.map((f, i) => <li key={'feature-' + i}>{f}</li>)}
      </ul>
    </div>
  );
}
