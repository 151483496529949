import React from "react";
import { Col, Row, Typography } from "antd";
import cx from "classnames";

import layoutStyle from "./layout.m.less";
import s from "./WinWin.m.less";

const { Title, Text } = Typography;

export type TWinWinProps = {};

export const WinWin: React.FC<TWinWinProps> = () => {
  return (
    <div className={s.winWin}>
      <Row className={s.padding}>
        <Col
          span={12}
          className={cx(layoutStyle.line, layoutStyle.right)}
          style={{ height: '80px' }}
        >
        </Col>
      </Row>
      <Row
        className={layoutStyle.widthLimiter}
        gutter={0}
        justify={"center"}
      >
        <Col
          span={24}
          xl={{ span: 8 }}
          className={s.sloganLeft}
        >
          <Title level={3} className={cx(s.text, s.right)}>
            Let employees <Text type={'secondary'}>win</Text><br />
            with your company
          </Title>
        </Col>
        <Col
          span={22}
          offset={1}
          xl={{ span: 8, offset: 0 }}
          className={s.orbit}
        >
          <div className={cx(layoutStyle.point, layoutStyle.middleLeft)} />
          <Row className={s.logo}>
            <Col className={cx(s.leftLogo, s.winText)} span={12}>Win</Col>
            <Col className={cx(s.rightLogo, s.winText)} span={12}>Win</Col>
          </Row>
          <div className={cx(layoutStyle.point, layoutStyle.middleRight)} />
        </Col>
        <Col
          span={24}
          xl={{ span: 8 }}
          className={s.sloganRight}
        >
          <Title level={3} className={s.text}>
            Let the company <Text type={'secondary'}>win</Text><br />
            with your employees
          </Title>
        </Col>
      </Row>
      <Row className={s.padding}>
        <Col
          span={12}
          className={cx(layoutStyle.line, layoutStyle.right)}
          style={{ height: '80px' }}
        >
        </Col>
      </Row>
    </div>
  );
}
