import React from 'react';
import './App.less';
import layoutStyle from './components/layout.m.less';
import { Layout, Typography } from "antd";
import { WinWin } from "./components/WinWin";
import { WhySlogans } from "./components/WhySlogans";
import { How } from "./components/How";
import { StatsSlogans } from "./components/StatsSlogans";
import { Prices } from "./components/Prices";

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

function App() {
  return (
    <Layout className={layoutStyle.layout}>
      <Header className={layoutStyle.header}>
        <div className={layoutStyle.widthLimiter}>
          <div className={layoutStyle.logo}></div>
          <Title className={layoutStyle.title} level={2}>Equalize Team</Title>
        </div>
      </Header>
      <Content>
        <WhySlogans/>
        <WinWin/>
        <How/>
        <StatsSlogans/>
        <Prices/>
      </Content>
      <Footer className={layoutStyle.footer}>
        <div className={layoutStyle.footerLogo}></div>
        <Title className={layoutStyle.footerTitle}>Equalize Team</Title>
      </Footer>
    </Layout>
  );
}

export default App;
