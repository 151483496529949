import React, { PropsWithChildren } from "react";
import cx from "classnames";
import layoutStyle from "./layout.m.less";
import s from './Slogan.m.less';
import { Col, Row, Typography } from "antd";

const { Title } = Typography;

export type TSloganProps = {
  extra: React.ReactNode,
  layout?: 'sloganLeft' | 'sloganRight',
  lineLengths?: [number] | [number, number],
};

export const Slogan: React.FC<PropsWithChildren<TSloganProps>> = ({
  children,
  extra,
  layout = 'sloganLeft',
  lineLengths= [12]
}) => {
  const renderLeft = () => <>
    <Row>
      <Col
        span={lineLengths[0] - 1}
        offset={1}
        xl={{ span: lineLengths[0] - 4, offset: 4 }}
        className={cx(layoutStyle.line, layoutStyle.topLeft)}
        style={{ height: 160 }}
      />
    </Row>
    <Row>
      <Col
        span={23}
        offset={1}
        xl={{ span: 8, offset: 4 }}
        className={cx(layoutStyle.line, layoutStyle.left, s.textContainer)}
      >
        <div className={cx(layoutStyle.point, layoutStyle.middleLeft)} />
        <Title level={3} className={s.text}>
          {children}
        </Title>
      </Col>
      <Col
        span={22}
        offset={1}
        xl={{ span: 12, offset: 0 }}
        className={s.extraLeft}
      >
        {extra}
      </Col>
    </Row>
    <Row>
      <Col
        span={(lineLengths[1] ?? lineLengths[0]) - 1}
        offset={1}
        xl={{ offset: 4, span: (lineLengths[1] ?? lineLengths[0]) - 4 }}
        className={cx(layoutStyle.line, layoutStyle.bottomLeft)}
        style={{ height: 160 }}
      />
    </Row>
  </>;

  const renderRight = () => <>
    <Row>
      <Col
        span={lineLengths[0]}
        offset={11}
        xl={{ offset: 8 }}
        className={cx(layoutStyle.line, layoutStyle.topRight)}
        style={{ height: 160 }}
      />
    </Row>
    <Row>
      <Col
        span={22}
        offset={1}
        xl={{ order: 1, span: 8, offset: 0 }}
        className={cx(layoutStyle.line, layoutStyle.right, s.textContainer, s.right)}
      >
        <div className={cx(layoutStyle.point, layoutStyle.middleRight)} />
        <Title level={3} className={cx(s.text, s.right)}>
          {children}
        </Title>
      </Col>
      <Col
        offset={1}
        span={22}
        xl={{ order: 0, span: 12, offset: 0 }}
        className={s.extraRight}
      >
        {extra}
      </Col>
    </Row>
    <Row>
      <Col
        span={lineLengths[1] ?? lineLengths[0] - 1}
        offset={12}
        xl={{ span: lineLengths[1] ?? lineLengths[0] - 4 }}
        className={cx(layoutStyle.line, layoutStyle.bottomRight)}
        style={{ height: 160 }}
      />
    </Row>
  </>

  return layout === 'sloganLeft' ? renderLeft() : renderRight();
}
